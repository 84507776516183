<template>
  <div class="all-categories-view">
    <!-- Leyenda de "Gastos" y total de gastos/presupuesto -->
    <div class="expenses-header">
      <h2>GASTOS</h2>
      <span class="expenses-total">{{ totalGastos }}</span
      >/<span class="expenses-budget">{{ totalPresupuesto }}</span>
    </div>

    <!-- Grid de categorías -->
    <div class="category-grid">
      <div
        v-for="category in flattenedCategories"
        :key="category.id"
        class="category-item"
      >
        <div class="category-icon-container">
          <feather-icon :icon="category.icon || '📂'" class="category-icon" />
        </div>
        <span class="category-name">{{ category.name || "-" }}</span>
        <span class="category-cost">{{
          category.cost ? category.cost : "0.00"
        }}</span>
        <span class="category-budget">{{
          category.budget ? category.budget : "0.00"
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    FeatherIcon,
  },
  data() {
    return {
      categories: [],
      flattenedCategories: [], // Almacenará las categorías aplanadas
      totalGastos: 0,
      totalPresupuesto: 0,
    }
  },
  created() {
    this.fetchCategories()
  },
  methods: {
    ...mapActions('groupedExpenses', ['fetchExpensesTypeCatalogs']),
    fetchCategories() {
      const { projectId } = this.$route.params
      this.fetchExpensesTypeCatalogs(projectId)
        .then(response => {
          this.categories = response.data
          this.flattenCategories() // Aplanamos las categorías
          this.calculateTotals() // Calculamos los totales
        })
        .catch(error => {
          console.error('Error fetching categories:', error)
        })
    },
    flattenCategories() {
      // Método recursivo para aplanar categorías
      const flatten = categories => categories.reduce((acc, category) => {
        acc.push(category) // Agregamos la categoría actual
        if (category.subcategories && category.subcategories.length > 0) {
          acc = acc.concat(flatten(category.subcategories)) // Aplanamos las subcategorías
        }
        return acc
      }, [])

      this.flattenedCategories = flatten(this.categories)
    },
    calculateTotals() {
      this.totalGastos = this.flattenedCategories.reduce(
        (acc, category) => acc + (parseFloat(category.cost) || 0),
        0,
      )
      this.totalPresupuesto = this.flattenedCategories.reduce(
        (acc, category) => acc + (parseFloat(category.budget) || 0),
        0,
      )
    },
  },
}
</script>

<style>
.all-categories-view {
  padding: 20px;
}

.expenses-header {
  text-align: center;
  margin-bottom: 20px;
}

.expenses-header h2 {
  font-size: 24px;
  color: #333;
}

.expenses-total {
  font-size: 28px;
  font-weight: bold;
  color: #7c127b;
}

.expenses-budget {
  font-size: 20px;
  color: #666;
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Ajuste responsivo */
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.category-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 50%; /* Círculo */
  border: 3px solid #c861c4;
  background-color: #fff;
  width: 150px;
  height: 150px;
  justify-content: center;
}

.category-icon-container {
  background-color: #2d2d2d;
  border-radius: 50%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-icon {
  font-size: 32px;
  color: #ffa500;
}

.category-name {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #4b4b4b;
}

.category-cost {
  margin-top: 5px;
  font-size: 16px;
  color: #c861c4;
}

.category-budget {
  font-size: 12px;
  color: #888888;
}
</style>
